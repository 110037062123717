export enum DesktopTemplate {
  'TWO_COLUMN' = 5,
  'TWO_STEP' = 2,
}

export enum ActiveTemplateEnum {
  'MOBILE_TWO_STEP' = 2,
  'DESKTOP_TWO_COLUMN' = 4,
  'DESKTOP_TWO_STEP' = 5,
}

export enum LayoutStep {
  StepOne = 1,
  StepTwo = 2,
}
