import { sanitize } from 'isomorphic-dompurify';

import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { isSubscription } from '../OrderSummary/utils';
import ProductInfo from '../OrderSummary/ProductInfo';
import SubscriptionInfo from '../OrderSummary/SubscriptionInfo';
import { usePageStore } from '../../zustand';
import { components } from '@/src/schemas/offer';
import { ActiveTemplateEnum } from '@/src/components/Layout/Layout.enums';

export interface IBumpState {
  isUsed: boolean;
}

export interface IProps {
  bump: components['schemas']['Bump'];
  currencySymbol: components['schemas']['Currency']['symbol'];
  buttonBackgroundColor?: components['schemas']['RGBA'];
}

const OrderBump = ({ bump, currencySymbol }: IProps) => {
  const bumpSelected = usePageStore((state) => state.bumpSelected);
  const setBumpSelected = usePageStore((state) => state.setBumpSelected);
  const activeTemplate = usePageStore((state) => state.activeTemplate);
  const {
    t,
    i18n: { language },
  } = useTranslation(['paymentPage', 'prices']);

  const toggleBump = () => {
    setBumpSelected(!bumpSelected);
  };

  const customization = bump.customization;
  const paymentOptions = bump.payment_option;

  return (
    <div
      className={`mt-6 lg:mt-8 bg-voma-background-color p-3 lg:p-6 pb-6 ${
        [ActiveTemplateEnum.MOBILE_TWO_STEP].includes(
          activeTemplate as ActiveTemplateEnum
        )
          ? 'border-accent-color border-t-4'
          : ''
      }`}
    >
      <div className="d-md-none">
        <h2 className="text-xl sm:text-2xl">
          {customization.form_preview.lead_text}
        </h2>
        <div
          className="mt-2 fr-view"
          dangerouslySetInnerHTML={{
            __html: sanitize(customization.form_preview.product_description),
          }}
        />
      </div>
      <div className="product-detail__wrapper">
        <div className="flex mt-6">
          <div className="flex-1 w-full max-w-[50%]">
            {customization.img_link && (
              <div className="relative w-full h-full">
                <Image
                  alt="Product logo"
                  src={customization.img_link}
                  loader={() => customization.img_link}
                  fill={true}
                  unoptimized={true}
                  style={{ objectFit: 'contain' }}
                  className="object-left-top"
                  priority
                />
              </div>
            )}
          </div>
          <div
            className={`flex-auto ${
              customization.img_link ? 'w-[50%] pl-4' : 'w-full'
            }`}
          >
            <ProductInfo
              product={customization.form_preview.product_name}
              option={paymentOptions}
              currency={currencySymbol}
              language={language}
              isBump={true}
            />
            {isSubscription(paymentOptions.payment_type) && (
              <SubscriptionInfo
                option={paymentOptions}
                currency={currencySymbol}
                language={language}
              />
            )}
            <button
              className={`max-w-sm w-full font-semibold border-2 rounded-r-sm py-2 px-4 mt-8 transition-all duration-300 focus-visible:outline-none ${
                bumpSelected
                  ? 'bg-transparent text-gray-900 border-gray-900'
                  : 'bg-gray-900 text-white'
              }`}
              data-testid="bump-button"
              onClick={toggleBump}
            >
              {bumpSelected ? t('remove') : t('addIt')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderBump;
