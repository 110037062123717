import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { usePageStore } from '@/src/common/zustand';
import NextButton from '../NextButton';
import { LayoutProps } from './Layout.types';
import { LayoutStep } from './Layout.enums';
import classNames from 'classnames';
// Preventing hydration mismatch error
// TODO: See if there is a better way to handle this
const PaymentOptionsList = dynamic(
  () => import('../PaymentOptions/PaymentOptionsList'),
  { ssr: false }
);

export const MobileTwoStep = (props: LayoutProps) => {
  const { t } = useTranslation('paymentPage');
  const [step, setStep] = useState<LayoutStep>(LayoutStep.StepOne);

  useEffect(() => {
    !props.previewMode && window.scrollTo(0, 0);
  }, [step, props.previewMode]);

  // Zustand store
  const contactInfoValid =
    usePageStore((state) => state.contactInformation?.isValid) || false;

  const setTriggerFormValidation = usePageStore(
    (state) => state.setTriggerFormValidation
  );

  const onNextButtonSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!contactInfoValid) {
      setTriggerFormValidation(true);
      return;
    }

    setStep(LayoutStep.StepTwo);
  };

  return (
    <>
      <div
        className={`container p-4 lg:p-6 max-w-3xl mx-auto bg-white ${
          props.className ?? ''
        }`}
      >
        {props.children}
        <div className={step === LayoutStep.StepOne ? 'display' : 'hidden'}>
          {props.deadline}
          {props.intro}
          {props.orderSummary}
          <div className={classNames(props.bonuses && 'my-[52px]')}>
            {props.bonuses}
          </div>
          <PaymentOptionsList />
          <form onSubmit={onNextButtonSubmit}>
            {props.contactInformation}
            <NextButton text={t('pageNext')} />
          </form>
        </div>
        <div className={step === LayoutStep.StepTwo ? 'display' : 'hidden'}>
          {props.mobileOrderSummary}
          {props.orderBump}
          {props.termsAndConditions}
          {step === LayoutStep.StepTwo && props.checkoutForm}
          <button
            className="block mx-auto text-center mt-6 text-button-background-color hover:cursor-pointer hover:opacity-90"
            onClick={() => setStep(LayoutStep.StepOne)}
          >
            &#8592; {t('pageReturn')}
          </button>
          {props.subscriptionNotices}
        </div>
      </div>
      {props.footer}
    </>
  );
};
