import dynamic from 'next/dynamic';
import { LayoutProps } from './Layout.types';
import classNames from 'classnames';
// Preventing hydration mismatch error
// TODO: See if there is a better way to handle this
const PaymentOptionsList = dynamic(
  () => import('../PaymentOptions/PaymentOptionsList'),
  { ssr: false }
);

const Sidebar = dynamic(() => import('@/components/Sidebar'));

export const DesktopTwoColumn = (props: LayoutProps) => {
  return (
    <div className="flex justify-center pl-10">
      {props.children}
      <div className="sm:w-[429px] w-[374px] flex-none mt-9">
        {props.orderSummary}
        <div className={classNames(props.bonuses && 'my-[52px]')}>
          {props.bonuses}
        </div>
        {props?.sidebar && <Sidebar {...props.sidebar} />}
      </div>
      <div className="xl:ml-20 max-w-3xl flex-1">
        <div>
          <div
            className={`border-t-4 border-accent-color px-4 lg:px-7 2xl:px-10 py-1 pb-[40px] mt-9 bg-white`}
            data-testid="main-box"
          >
            {props.deadline}
            {props.intro}
            <PaymentOptionsList />
            {props.contactInformation}
            {props.orderBump}
            {props.termsAndConditions}
            {props.checkoutForm}
            {props.subscriptionNotices}
          </div>
          {props.footer}
        </div>
      </div>
    </div>
  );
};
