import { useTranslation } from 'next-i18next';
import { TwoArrowsDownIcon } from '../../icons';
import { format, utcToZonedTime } from 'date-fns-tz';
import { es, de, fr, pt, enUS, tr, sv } from 'date-fns/locale';
import { useOfferStore } from '@/src/common/zustand';
import { FC } from 'react';
// import GoogleFontLoader from 'react-google-font';

const dateLocales = { es, de, fr, pt, en: enUS, tr, sv };

const DeadlineText: FC<{ deadlineDate: Date }> = ({
  deadlineDate,
}): JSX.Element => {
  const { t, i18n } = useTranslation('paymentPage');
  const timeZone = useOfferStore(
    (state) => state.offer?.deadline?.main_offer?.timezone
  );
  const isEnglish = i18n.language === 'en';

  const dayOfWeek = format(deadlineDate, 'EEEE', {
    locale: dateLocales[i18n.language],
    timeZone,
  });
  const hour = format(deadlineDate, isEnglish ? 'h:mm' : 'H:mm', {
    locale: dateLocales[i18n.language],
    timeZone,
  });
  const ampm = isEnglish ? format(deadlineDate, 'a', { timeZone }) : '';

  const tzString = format(utcToZonedTime(new Date(), timeZone!), 'zzz', {
    timeZone,
  });
  return (
    <>
      {/* <GoogleFontLoader
        fonts={[
          {
            font: 'Lato',
            weights: [700],
          },
        ]}
      /> */}
      <p className="text-[12px] font-normal tracking-wider font-[Lato] mt-[12px] h-2.5 text-right leading-none">
        {t('offerExpiresOn')}
        <span> </span>
        {/* <span className="font-['Lato']"> */}
        <span>
          {dayOfWeek} {t('at')} {hour} {ampm} {tzString}
        </span>
        <TwoArrowsDownIcon className="inline-block ml-1 mb-0.5 relative lg:hidden" />
      </p>
    </>
  );
};

export default DeadlineText;
