import { FC } from 'react';
// import GoogleFontLoader from 'react-google-font';
const TITLE_LIMIT = 44;

const Title: FC<{ title: string }> = ({ title }): JSX.Element => {
  return (
    <>
      {/* <GoogleFontLoader
        fonts={[
          {
            font: 'Lato',
            weights: [700],
          },
        ]}
      /> */}
      {/* <p className={`font-['Lato'] mb-[15px] lg:mb-0 tracking-[-0.36px] lg:tracking-tighter text-lg lg:text-[26px] leading-4 lg:leading-[26px]`} */}
      <p
        className={`mb-[15px] lg:mb-0 tracking-[-0.36px] lg:tracking-tighter text-lg lg:text-[26px] leading-4 lg:leading-[26px] max-w-[270px] break-words whitespace-normal`}
      >
        {title.slice(0, TITLE_LIMIT)}
      </p>
    </>
  );
};

export default Title;
