import { usePageStore } from '@/src/common/zustand';
import { components } from '@/src/schemas/offer';
import { FC, PropsWithChildren, createContext } from 'react';
import Type from './Type';
import Description from './Description';
import Price from './Price';
import SelectedSvg from './SelectedSvg';

type SubComponents = {
  Type: typeof Type;
  Description: typeof Description;
  Price: typeof Price;
  SelectedSvg: typeof SelectedSvg;
};

export type PaymentOptionContextType = {
  option: components['schemas']['PaymentOption'] | null;
  selected: boolean;
  color: string;
};

export const PaymentOptionContext = createContext<PaymentOptionContextType>({
  option: null,
  selected: false,
  color: '#000000',
});

const PaymentOption: FC<
  PropsWithChildren<{
    option: components['schemas']['PaymentOption'];
    selected: boolean;
    color: string;
  }>
> &
  SubComponents = ({ children, option, selected, color }) => {
  const setSelectedPaymentOptionId = usePageStore(
    (state) => state.setSelectedPaymentOptionId
  );

  return (
    <div
      onClick={() => setSelectedPaymentOptionId(option.id!)}
      onKeyDown={(e) =>
        e.key === 'Enter' && setSelectedPaymentOptionId(option.id!)
      }
      className={`w-full h-24 rounded-md border-2 ${
        selected ? 'border-accent-color' : 'border-gray-200'
      } cursor-pointer`}
      tabIndex={0}
      data-testid={`payment-option-${option.id}`}
    >
      <PaymentOptionContext.Provider
        value={{
          option,
          selected,
          color,
        }}
      >
        {children}
      </PaymentOptionContext.Provider>
    </div>
  );
};

PaymentOption.Type = Type;
PaymentOption.Description = Description;
PaymentOption.Price = Price;
PaymentOption.SelectedSvg = SelectedSvg;

export default PaymentOption;
