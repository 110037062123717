import { useEffect, useState } from 'react';

import { usePageStore } from '@/src/common/zustand';
import useMedia from '@/common/hooks/useMedia';
import { DesktopTwoColumn } from './DesktopTwoColumn';
import { DesktopTwoStep } from './DesktopTwoStep';

import { ActiveTemplateEnum, DesktopTemplate } from './Layout.enums';
import { LayoutProps } from './Layout.types';
import { TARGET_MEDIA_WIDTH } from './Layout.constants';
import { MobileTwoStep } from './MobileTwoStep';
import { usePaymentOption } from '@/src/hooks';

const getCurrentTemplate = (
  showMobile: boolean,
  desktopPageTemplate: DesktopTemplate,
  overrideTemplate?: DesktopTemplate
): ActiveTemplateEnum => {
  if ((overrideTemplate as number) > 0) {
    if (overrideTemplate === DesktopTemplate.TWO_COLUMN)
      return ActiveTemplateEnum.DESKTOP_TWO_COLUMN;

    return ActiveTemplateEnum.DESKTOP_TWO_STEP;
  }

  if (showMobile) {
    return ActiveTemplateEnum.MOBILE_TWO_STEP;
  }

  if (!showMobile && desktopPageTemplate === DesktopTemplate.TWO_COLUMN) {
    return ActiveTemplateEnum.DESKTOP_TWO_COLUMN;
  } else if (!showMobile && desktopPageTemplate === DesktopTemplate.TWO_STEP) {
    return ActiveTemplateEnum.DESKTOP_TWO_STEP;
  }

  return ActiveTemplateEnum.DESKTOP_TWO_STEP;
};

const Layout = (props: LayoutProps) => {
  const [activeTemplate, setActiveTemplate] = useState(
    ActiveTemplateEnum.MOBILE_TWO_STEP
  );

  const [isForcedMobile, setIsForcedMobile] = useState<boolean>(false);

  const paymentOption = usePaymentOption();

  const setActiveTemplateStore = usePageStore(
    (state) => state.setActiveTemplate
  );

  const setPreviewMode = usePageStore((state) => state.setPreviewMode);

  const setSubscriptionRebills = usePageStore(
    (state) => state.setSubscriptionRebills
  );

  const isMobile = useMedia(TARGET_MEDIA_WIDTH);

  useEffect(() => {
    setSubscriptionRebills(paymentOption?.rebills || 0);
  }, [paymentOption?.rebills]);

  useEffect(() => {
    if ([true, false].includes(props.forceMobile!)) {
      setIsForcedMobile(props.forceMobile!);
    }
  }, [props.forceMobile]);

  useEffect(() => {
    if ([true, false].includes(props.previewMode!)) {
      setPreviewMode(props.previewMode!);
    }
  }, [props.previewMode]);

  useEffect(() => {
    const showMobile = isMobile || isForcedMobile;
    const template = getCurrentTemplate(
      showMobile,
      props.desktopPageTemplate!,
      props.overrideTemplate
    );

    setActiveTemplate(template);
    setActiveTemplateStore(template);
  }, [isMobile, isForcedMobile]);

  switch (activeTemplate) {
    case ActiveTemplateEnum.DESKTOP_TWO_COLUMN:
      return <DesktopTwoColumn {...props} />;
    case ActiveTemplateEnum.DESKTOP_TWO_STEP:
      return <DesktopTwoStep {...props} />;
    case ActiveTemplateEnum.MOBILE_TWO_STEP:
      return <MobileTwoStep {...props} />;
    default:
      return <DesktopTwoColumn {...props} />;
  }
};

export default Layout;
